.sidebar__menu__list.active {
  background-color: $bg-menu-active-color;
  border-right: 3px solid $accent;
  border-radius: 1px;
}
.sidebar__menu__list {
  margin: auto;
  margin-bottom: 0.1rem;
  .sidebar__menu__link.disable {
    pointer-events: none;
    p {
      color: $text-disabled !important;
    }
  }
  .sidebar__menu__link.active {
    &:hover {
      color: $text-menu-active-color !important;
    }
    p {
      color: $text-menu-active-color !important;
    }
  }
  .sidebar__menu__link {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2px 30px;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
    &:focus {
      outline: none;
    }
    .icon_text {
      display: flex;
      align-items: center;
      &:hover {
        color: $text-menu-active-color;
      }
      p {
        font-weight: 500;
        font-size: 1rem;
        color: $text-black;
        text-transform: capitalize;
        &:hover {
          color: $text-menu-active-color;
        }
        &:visited {
          color: none;
        }
        &:active {
          color: $text-black;
        }
      }
    }
  }
  ul {
    transform: translateX(30px);
    li {
      margin: 1rem 0;
      &:focus {
        outline: none;
      }
      &:hover {
        color: $text-menu-active-color;
      }
      .nav__link {
        color: $text-black;
        text-transform: capitalize;
        font-size: 0.9rem;
        font-weight: 400;
        &:hover {
          color: $text-menu-active-color;
        }
        &:visited {
          color: none;
        }
      }
      .active {
        color: $text-menu-active-color;
      }
    }
  }
}
