.link {
  text-decoration: none;
  &:focus {
    outline: none;
  }
}

.course_link {
  color: $text-black;
}

.logout-link {
  text-decoration: none;
  &:focus {
    outline: none;
  }
}
