* {
  box-sizing: border-box;
  font-family: $font-family;
  outline: none;
}

*:focus {
  outline: none;
}

.container {
  width: 1280px;
  margin: auto;
}
