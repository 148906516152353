html {
  scroll-behavior: smooth;
}

.main__container {
  display: flex;
  width: 100%;
  margin: 0;
  padding: 0;

  .sidebar__section {
    width: 18%;
    background: $bg-white;
    position: fixed;
    left: 0;
    height: 90%;
    overflow: auto;
    transition: all 0.3s ease-in-out;
    overflow-x: hidden;
    z-index: 999;
    border-right: $border-header;
    @include breakpoints(tablet) {
      position: absolute;
      width: 60%;
      animation: menuToggle 0.5s;
    }

    // scroll bar
    &::-webkit-scrollbar {
      width: 4px;
    }
    /* Track */
    &::-webkit-scrollbar-track {
      background: $bg-white;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #dcdada;
    }
    &::-webkit-scrollbar-thumb {
      background: #dcdada;
    }
  }
  .sidebar__section_hide {
    display: none;
  }

  .sidebar__section.toggled {
    width: 0%;
  }

  .main__content__section {
    position: relative;
    margin-left: auto;
    transition: all 0.3s ease-in-out;
    width: calc(100% - 18%);
    @include breakpoints(tablet) {
      width: 100%;
    }

    .main__content__container {
      min-height: 70vh;
      // min-height: 45.5vh;
    }
  }

  .main__content__section_wide {
    position: relative;
    margin-left: auto;
    transition: all 0.3s ease-in-out;
    width: 100%;

    .main__content__container {
      min-height: 70vh;
      // min-height: 45.5vh;
    }
  }

  .main__content__section.toggled {
    width: 100%;
  }
}

@keyframes menuToggle {
  from {
    width: 0%;
    transition: all 0.3s ease-in-out;
  }
  to {
    width: 60%;
    transition: all 0.3s ease-in-out;
  }
}
