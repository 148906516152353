footer {
  display: flex;
  align-items: center;
  width: 100%;
  height: 10%;
  //   box-shadow: $box-shadow-2;
  background-color: $bg-white;
  position: fixed;
  bottom: 0;
  border-top: $border-header;
  z-index: 1100;
  .left__text {
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      @include breakpoints(tablet) {
        font-size: 0.5rem;
      }
    }
  }
  .right__text {
    width: 80%;
    p {
      color: $text-p-color !important;
      transition: all 0.3s ease-in-out;
      font-size: 15px;
      @include breakpoints(tablet) {
        font-size: 0.5rem;
      }
      a {
        color: $text-menu-active-color !important;
        font-weight: 600;
      }
    }
  }
}
