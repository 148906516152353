.btn__primary {
  display: inline-block;
  padding: 8px 16px;
  font-size: 14px;
  color: $white;
  background-color: $primary;
  border: none;
  border-radius: 4px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  position: relative;
  overflow: hidden;
}

.btn__error {
  display: inline-block;
  padding: 8px 16px;
  font-size: 14px;
  color: $white;
  background-color: $error;
  border: none;
  border-radius: 4px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  position: relative;
  overflow: hidden;
}
.btn__base-300 {
  display: inline-block;
  padding: 8px 16px;
  font-size: 14px;
  color: $white;
  background-color: $base-300;
  border: none;
  border-radius: 8px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  position: relative;
  overflow: hidden;
  @include breakpoints(tablet) {
    padding: 4px 8px;
    font-size: 12px;
  }
}

.btn {
  display: inline-block;
  padding: 8px 16px;
  font-size: 14px;
  color: $dark;
  background-color: $bg-gray;
  border: none;
  border-radius: 4px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  position: relative;
  overflow: hidden;
}
