.nav__toggle__btn__icon {
  color: #ffffff;
  font-size: xx-large;
  z-index: 10;
  position: relative;
  // transition: all 1 ease-in-out;
}

.nav__toggle__btn__icon.cross {
  position: relative;
  z-index: 10;
  color: red;
}

.sidebar__menu__icon {
  transition: all 0.3s ease-in-out;
  display: block;
  opacity: 1;
  font-size: 1.5rem;
  margin-right: 0.6rem;
  color: $text-black;
  &:hover {
    color: $text-menu-active-color;
  }
  &:visited {
    color: none;
  }
  &:active {
    color: $text-black;
  }
}

.sidebar__menu__icon.active {
  color: $text-menu-active-color !important;
  &:visited {
    color: none;
  }
}

.sidebar__menu__icon.disable {
  color: $text-disabled !important;
}

.hamburger_icon {
  color: $text-black;
  font-size: 1.6rem;
  // box-shadow: $box-shadow-navicon;
}

.navbar__search__icon {
  position: relative;
  right: 40px;
  top: 5px;
  font-size: 1.5rem;
  color: $text-gray;
}

.notication__icon {
  font-size: 1.3rem;
  margin-right: 0.3rem;
  color: #8080a2;
}
.notification__dot {
  color: red;
  position: relative;
  right: 15px;
  bottom: 20px;
}

.card__icon {
  font-size: 1.5rem;
  color: $white;
}
