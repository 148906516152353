.medicine__main__container {
  background-color: $bg-gray;
  padding: 1rem 2rem 10rem 2rem;
  .page__heding__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .heading__and__path {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 30%;
      @include breakpoints(tablet) {
        width: 60%;
      }
      .page__heading {
        h5 {
          margin: 0;
          color: $base-300;
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          line-height: 28px;
          @include breakpoints(tablet) {
            font-size: 10px;
          }
        }
      }
      .route__path {
        .path {
          color: $base-300;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px; /* 133.333% */
          letter-spacing: 0.5px;
          @include breakpoints(tablet) {
            font-size: 9px;
          }
          span {
            color: #000;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
            letter-spacing: 0.5px;
            @include breakpoints(tablet) {
              font-size: 9px;
            }
          }
        }
      }
    }
  }
  .medicine__page__table__container {
    margin-top: 3rem;
    padding: 0.5rem;
    background-color: $bg-white;
    border-radius: 7px;
    box-shadow: $box-shadow-1;
    @include breakpoints(tablet) {
      margin-top: 1rem;
    }
  }
}
